import { CdkColumnDef } from "@angular/cdk/table";
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatColumnDef, MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";

import { BaseComponent } from "../../../base/components/base/base-component";
import { MeasuredValue } from "../../datamodel/measured-value";
import { Measurement } from "../../datamodel/measurement";
import { SpecialColumns } from "../../datamodel/special-columns";
import { UiHelper } from "../../helpers/ui-helper";
import { DialogService } from "../../services/dialog/dialog.service";
import { GraphComponent } from "../graph/graph.component";
import { TableRow } from "./table-row";

/**
 * MeasurementTableComponent allows to display measurements of different formats in a table.
 */
@Component({
    selector: "app-measurement-table",
    templateUrl: "./measurement-table.component.html",
    styleUrls: ["./measurement-table.component.scss"],
    providers: [
        MatColumnDef,
        CdkColumnDef
    ]
})
export class MeasurementTableComponent extends BaseComponent {
    constructor(
        private translationService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: DialogService
    ) {
        super();
    }

    protected readonly specialColumns: typeof SpecialColumns = SpecialColumns;

    @Input()
    public highlightLastMeasurement: boolean = false;

    @Input()
    public showStatistics: boolean = false;

    @Input()
    public allowSaveChart: boolean = false;

    @Input()
    public confirmDelete: boolean = true;

    public lastMeasurement: Measurement|undefined = undefined;

    public displayedColumns: Array<string> = [];
    public statisticsColumns: Array<string> = [];
    public statistics: Array<{ [key: string]: string }> = [];
    public displayedRows: Array<TableRow> = [];

    @Input()
    public isReadonly: boolean = false;
    public measurementTableComponent = MeasurementTableComponent;
    @ViewChild("measurementMatTable")
    private measurementMatTable?: MatTable<any>;
    @ViewChild("statisticsTable")
    private statisticsTable?: MatTable<any>;
    private measurementsBacking: Array<Measurement> = [];
    protected readonly uiHelper = UiHelper;

    @Output("deleteMeasurement")
    public deleteMeasurementEvent: EventEmitter<Measurement> = new EventEmitter<Measurement>();
    @Output("dirtyState")
    public dirtyStateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output("chartAdded")
    public chartAddedEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output("measurementUpdated")
    public measurementUpdated: EventEmitter<Measurement> = new EventEmitter<Measurement>();

    public get measurements(): Array<Measurement> {
        return this.measurementsBacking;
    }

    @Input()
    public set measurements(measurements: Array<Measurement>) {
        this.displayedColumns = this.calcAllColumns();
        this.statisticsColumns = this.calculateStatisticsColumns();
        this.measurementsBacking = measurements;
        this.calculateStatistics();
        this.refresh();
    }

    public override componentInit(): void {
        this.refresh();
    }

    public override componentDestroy(): void {
        // Do nothing
    }

    public static getPrecisionForColumn(columnName: string, measurement: Measurement): number {
        if (measurement?.values) {
            const measuredValue: MeasuredValue|undefined = measurement.values.find((value: MeasuredValue) => value.name == columnName);
            if (measuredValue?.unit) {
                return UiHelper.getPrecisionForUnit(measuredValue.unit);
            }
        }
        return 1;
    }

    public static getValueForColumn(columnName: string, measurement: Measurement): string {
        return UiHelper.getValueForColumn(columnName, measurement);
    }

    public static getUnitForColumn(columnName: string, measurements: Array<Measurement>, separator: boolean = false, translate: boolean = false): string {
        return UiHelper.getUnitForColumn(columnName, measurements, separator, translate);
    }

    public static getTitleForColumn(columnName: string): string {
        return UiHelper.getTitleForColumn(columnName);
    }

    public static calculateColumns(measurements: Array<Measurement>): Array<string> {
        const cols: Array<string> = [];

        cols.push(SpecialColumns.name);
        cols.push(...UiHelper.measurementsToColumnNames(measurements));
        cols.push(SpecialColumns.comments);

        return cols;
    }

    public getStatisticsValue(statistic: any, column: string): string {
        if (statistic[column]) {
            return statistic[column];
        } else {
            return Object.values(SpecialColumns).some((value: string) => value == column) ? "" : "---";
        }
    }

    private updateLastMeasurement(): void {
        this.lastMeasurement = this.measurements.length > 0 ? this.measurements[0] : undefined;
    }

    public refresh(): void {
        this.updateLastMeasurement();

        this.displayedColumns = this.calcAllColumns();
        this.statisticsColumns = this.calculateStatisticsColumns();
        this.calculateStatistics();

        this.measurementMatTable?.renderRows();
        this.statisticsTable?.renderRows();
        this.changeDetectorRef.detectChanges();

        this.buildTableRows();
    }

    private buildTableRows(): void {
        let statsIndex: number = 0;
        this.displayedRows = [
            ...this.measurements.map<TableRow>((measurement: Measurement) => ({
                rowType: "measurement",
                measurement: measurement,
                separator: false
            })),
            ...this.statistics.map<TableRow>((statistic: { [p: string]: string }) => ({
                rowType: "statistic",
                title: "???",
                statistic: statistic,
                separator: statsIndex++ == 0
            }))
        ];
    }

    private calcAllColumns(): Array<string> {
        const columns: Array<string> = [];

        columns.push(SpecialColumns.localId);

        const hasCharts: boolean = this.measurements.some((measurement: Measurement) => measurement.charts.length > 0);
        if (hasCharts) {
            columns.push(SpecialColumns.charts);
        }

        columns.push(...MeasurementTableComponent.calculateColumns(this.measurements));
        columns.push(SpecialColumns.actions);
        return columns;
    }

    public async editName(measurement: Measurement): Promise<void> {
        if (this.isReadonly) {
            return;
        }

        const text: string|undefined = await this.dialogService.prompt(this.translationService.instant("Measurement.name"), undefined, measurement.name);
        if (text !== undefined) {
            measurement.name = text;
            measurement.iteration = measurement.iteration ? measurement.iteration + 1 : 1;
            this.measurementMatTable?.renderRows();
            this.measurementUpdated.emit(measurement);
            this.dirtyStateEvent.emit(true);
        }
    }

    public async editComment(measurement: Measurement): Promise<void> {
        if (this.isReadonly) {
            return;
        }

        const text: string|undefined = await this.dialogService.prompt(this.translationService.instant("Measurement.comment"), undefined, measurement.comment);
        if (text !== undefined) {
            measurement.comment = text;
            measurement.iteration = measurement.iteration ? measurement.iteration + 1 : 1;
            this.measurementMatTable?.renderRows();
            this.measurementUpdated.emit(measurement);
            this.dirtyStateEvent.emit(true);
        }
    }

    public async openChart(measurement: Measurement): Promise<void> {
        for (const chart of measurement.charts) {
            chart.title = UiHelper.measurementToChartTitle(measurement);
        }

        const imageUrl: string|undefined = await this.dialogService.openDialog<string|undefined>(GraphComponent, {
            fullscreen: true
        }, {
            charts: measurement.charts,
            allowSave: this.allowSaveChart
        });

        if (imageUrl) {
            this.chartAddedEvent.emit(imageUrl);
        }
    }

    private calculateStatisticsColumns(): Array<string> {
        const knownColumns: Array<string> = Object.values(SpecialColumns);
        const columns: Array<string> = [SpecialColumns.statisticType];
        columns.push(...this.displayedColumns.filter((c: string) => !knownColumns.includes(c)));
        return columns;
    }

    private calculateStatistics(): void {
        if (!this.showStatistics || this.measurements.length <= 1) {
            this.statistics = [];
            return;
        }

        const max: {[key: string]: string } = {};
        max[SpecialColumns.statisticType] = "Statistics.max";

        const min: {[key: string]: string } = {};
        min[SpecialColumns.statisticType] = "Statistics.min";

        const avg: {[key: string]: string } = {};
        avg[SpecialColumns.statisticType] = "Statistics.avg";

        const stdDev: {[key: string]: string } = {};
        stdDev[SpecialColumns.statisticType] = "Statistics.stdDev";

        for (const statisticColumn of this.statisticsColumns) {
            if (statisticColumn == SpecialColumns.statisticType) {
                continue;
            }
            max[statisticColumn] = this.max(statisticColumn);
            min[statisticColumn] = this.min(statisticColumn);
            avg[statisticColumn] = this.avg(statisticColumn);
            stdDev[statisticColumn] = this.stdDev(statisticColumn);
        }

        this.statistics = [avg, min, max, stdDev];
    }

    private max(column: string): string {
        let max: number|undefined = undefined;
        let precision: number = 1;
        for (const measurement of this.measurementsBacking) {
            const value: number = parseFloat(MeasurementTableComponent.getValueForColumn(column, measurement));
            if (!isNaN(value)) {
                precision = Math.max(precision, MeasurementTableComponent.getPrecisionForColumn(column, measurement));
                max = max === undefined || value > max ? value : max;
            }
        }

        return max === undefined || isNaN(max) ? "---" : UiHelper.format(`${max}`, precision);
    }

    private min(column: string): string {
        let min: number|undefined = undefined;
        let precision: number = 1;
        for (const measurement of this.measurementsBacking) {
            const value: number = parseFloat(MeasurementTableComponent.getValueForColumn(column, measurement));
            if (!isNaN(value)) {
                precision = Math.max(precision, MeasurementTableComponent.getPrecisionForColumn(column, measurement));
                min = min === undefined || (min < 0 || value < min) ? value : min;
            }
        }

        return min === undefined || isNaN(min) ? "---" : UiHelper.format(`${min}`, precision);
    }

    private avg(column: string): string {
        const values: Array<number> = [];
        let precision: number = 1;
        for (const measurement of this.measurements) {
            const value: number = parseFloat(MeasurementTableComponent.getValueForColumn(column, measurement));
            if (!isNaN(value)) {
                precision = Math.max(precision, MeasurementTableComponent.getPrecisionForColumn(column, measurement));
                values.push(value);
            }
        }

        if (values.length > 0) {
            let sum: number = 0.0;
            values.forEach((v: number) => sum += v);
            return UiHelper.format(`${(sum / (values.length))}`, precision);
        }
        return "---";
    }

    private stdDev(column: string): string {
        const values: Array<number> = [];
        let precision: number = 1;
        for (const measurement of this.measurements) {
            const value: number = parseFloat(MeasurementTableComponent.getValueForColumn(column, measurement));
            if (!isNaN(value)) {
                precision = Math.max(precision, MeasurementTableComponent.getPrecisionForColumn(column, measurement));
                values.push(value);
            }
        }

        if (values.length > 0) {
            // Step 1: Calculate the mean
            let sum: number = 0.0;
            values.forEach((v: number) => sum += v);
            const mean: number = sum / values.length;

            // Step 2: Compute squared differences from the mean
            let squareDiffsSum: number = 0.0;
            values.forEach((v: number) => squareDiffsSum += Math.pow(v - mean, 2));

            // Step 3: Calculate the mean of these squared differences
            const avgSquareDiff: number = squareDiffsSum / values.length;

            // Step 4: Take the square root
            return UiHelper.format(`${Math.sqrt(avgSquareDiff)}`, precision);
        }
        return "---";
    }

    public async deleteMeasurement(measurement: Measurement): Promise<void> {
        const result: boolean = !this.confirmDelete || await this.dialogService.openDeleteDialog("DeleteDialog.deleteMeasurementTitle", "DeleteDialog.deleteMeasurementMessage");
        if (result) {
            this.deleteMeasurementEvent.emit(measurement);
        }
    }
}
